import { Routes } from '@angular/router';

export const urlRoutes = {
    HOME: '',
    CONTACTS : 'contatti',
    PRIVACY : 'privacy-policy',
    COOKIE:  'cookie-policy',
    THEMES : 'tematiche',
    ARCHIVE : 'archivio',
    ARCHIVE_EDITION : 'archivio/edizioni',
    DETAIL_EDITION : 'edizione',
    PAGE_404 : 'page-404',
  }

export const routes: Routes = [
    { path: urlRoutes.HOME, loadComponent: () => import('./pages/home/home.component'), },
    { path: urlRoutes.CONTACTS, loadComponent: () => import('./pages/contacts/contacts.component') },
    { path: urlRoutes.PRIVACY, loadComponent: () => import('./pages/privacy-policy.component') },
    { path: urlRoutes.COOKIE, loadComponent: () => import('./pages/cookie-policy.component') },
    { path: urlRoutes.ARCHIVE, loadComponent: () => import('./pages/archive/archive.component') },
    { path: urlRoutes.ARCHIVE_EDITION, loadComponent: () => import('./pages/archive-edition/archive-edition.component') },
    { path: `${urlRoutes.DETAIL_EDITION}/:magazineSlug/:slug`, loadComponent: () => import('./pages/detail-edition/detail-edition.component') },
    { path: `${urlRoutes.THEMES}/:theme`, loadComponent: () => import('./pages/themes/themes.component') },
    { path: `${urlRoutes.THEMES}/:theme/:category`, loadComponent: () => import('./pages/themes/themes.component') },
    { path: ':category/:number/:slug', loadComponent: () => import('./pages/detail-article/detail-article.component') },
    { path: urlRoutes.PAGE_404, loadComponent: () => import('./pages/page404/page404.component')  },
    { path: '**', pathMatch: 'full', loadComponent: () => import('./pages/page404/page404.component') }
];
